import { GrYoga } from 'react-icons/gr'
import { FaBookReader } from 'react-icons/fa'
import { FaGuitar } from 'react-icons/fa'
import { HobbyCardProps } from '../../common/HobbyCard'

export const HOBBIES: HobbyCardProps[] = [
  {
    icon: <GrYoga />,
    title: 'Yoga',
    description:
      'I practice Ashtanga yoga once a week in a small studio in Rochor, Singapore, where I get to unravel my stress even as I cultivate strength and discipline under the perceptive eyes of my teachers. The mat is where the buzz of the city fades into focused breaths.',
  },
  {
    icon: <FaBookReader />,
    title: 'Reading',
    description:
      'Increasingly I find that reading stands as my anchor, a sanctuary of sustained focus and thinking against the rapid pace and fragmented distractions of a digitally-mediated world.',
  },
  {
    icon: <FaGuitar />,
    title: 'Playing the ukulele',
    description:
      "The ukulele is my bagpack-sized escape into a world of musical whims and fancies. On some evenings, I transform into a one-person band covering songs. Yet, the uke is more than a cover artist; it's also my muse for spontaneous songwriting sessions.",
  },
]
