import Header from './Header'
import Footer from './Footer'
import { JSX } from 'react'
import { Grid, GridItem } from '@chakra-ui/react'

export const Layout = ({ children }: { children: JSX.Element }) => {
  return (
    <Grid
      templateAreas={`"header" "main" "main" "footer"`}
      gridTemplateRows={'50px 1fr 1fr 30px'}
      minHeight="100vh"
      color="black"
      gap={5}
    >
      <GridItem area={'header'}>
        <Header />
      </GridItem>
      <GridItem p={5} area={'main'}>
        <main>{children}</main>
      </GridItem>
      <GridItem area={'footer'}>
        <Footer />
      </GridItem>
    </Grid>
  )
}

export default Layout
