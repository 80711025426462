// const COLOUR_PICKER_OPTIONS = [
//     { label: 'Red', value: 'red'},
//     {}
// ]

export const COLOUR_PICKER_OPTIONS = [
  'red',
  'yellow',
  'green',
  'blue',
  'purple',
]
