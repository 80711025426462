import { Link } from 'react-router-dom'
import { HStack, ButtonGroup, Button } from '@chakra-ui/react'

const NavBar = () => {
  return (
    <HStack align={'center'} justifyContent={'center'}>
      <ButtonGroup>
        <Button colorScheme="gray" variant="ghost">
          <Link to="/">Home</Link>
        </Button>
        <Button colorScheme="gray" variant="ghost">
          <Link to="/profile">Profile</Link>
        </Button>
        <Button colorScheme="gray" variant="ghost">
          <Link to="/colour-picker">Colour Picker</Link>
        </Button>
      </ButtonGroup>
    </HStack>
  )
}

export default NavBar
