import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  FormErrorMessage,
  FormLabel,
  FormControl,
} from '@chakra-ui/react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { COLOUR_PICKER_OPTIONS } from './ColourPicker.helpers'
import { useEffect } from 'react'

export type FormValues = {
  colour: string
  height: number
}
interface ColourPickerProps {
  isOpen: boolean
  onClose: () => void
  onEdit: ({ colour, height }: FormValues) => void
  initialValues: FormValues
}

export const ColourPickerModal = ({
  isOpen,
  onClose,
  onEdit,
  initialValues,
}: ColourPickerProps) => {
  const { colour: initialColour, height: initialHeight } = initialValues

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: { colour: initialColour, height: initialHeight },
    mode: 'onSubmit',
  })

  // Just to demonstrate the utility of using controlled components
  // Where, together with setValue(), you can dynamically set the value of a registered field like this!
  useEffect(() => {
    const inputColour = getValues('colour')
    if (inputColour === 'purple') {
      setValue('height', 500)
    }
  }, [getValues('colour')])

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    onEdit(data)
    onClose()
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>Edit your colour picker!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Controller
              name="colour"
              control={control}
              rules={{
                required: 'This is required.',
              }}
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <FormControl isInvalid={invalid}>
                  <FormLabel htmlFor="colour">Colour</FormLabel>
                  <Select
                    id="colour"
                    width="50%"
                    mb={5}
                    onChange={(e) => {
                      console.log('e:', e.target.value)
                      if (e.target.value === 'purple') {
                        setValue('height', 500)
                      }
                      onChange(e.target.value)
                    }}
                    value={value}
                  >
                    {COLOUR_PICKER_OPTIONS.map((colour) => (
                      <option key={colour} value={colour}>{`${colour
                        .charAt(0)
                        .toUpperCase()}${colour.slice(1)}`}</option>
                    ))}
                  </Select>
                  <FormErrorMessage>
                    {!!error && !!error.message}
                  </FormErrorMessage>
                </FormControl>
              )}
            ></Controller>
            <Controller
              name="height"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'This is required',
                },
                min: {
                  value: 50,
                  message:
                    "Any height smaller than 50 and you won't be able to see!",
                },
                max: {
                  value: 800,
                  message: 'No need to make it more than 800 (;',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <FormControl isInvalid={invalid}>
                  <FormLabel htmlFor="height">Height</FormLabel>
                  <NumberInput
                    onChange={(_, valueNumber) => onChange(valueNumber)}
                    value={isNaN(value) ? 0 : value}
                    id="height"
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <FormErrorMessage>{error && error.message}</FormErrorMessage>
                </FormControl>
              )}
            ></Controller>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              colorScheme="teal"
              variant="outline"
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              type="submit"
              colorScheme="teal"
              variant="solid"
              isLoading={isSubmitting}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
