import { Box, VStack, Text, Button } from '@chakra-ui/react'
import { useState } from 'react'
import Layout from '../../common/Layout'
import { ColourPickerModal } from './ColourPickerModal'
import { FormValues } from './ColourPickerModal'
import { useDisclosure } from '@chakra-ui/react'

export const ColourPicker = () => {
  const [colour, setColour] = useState('red')
  const [height, setHeight] = useState(50)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const updateColourPicker = ({ colour, height }: FormValues) => {
    setColour(colour)
    setHeight(height)
  }

  return (
    <Layout>
      <>
        <VStack>
          <Box
            width="50%"
            height={height}
            borderWidth="1px"
            borderRadius="lg"
            bg={colour}
          ></Box>
          <Text>
            Colour: {`${colour.charAt(0).toUpperCase()}${colour.slice(1)}`}
          </Text>
          <Text>Height: {`${height}px`}</Text>
          <Button onClick={onOpen}>Edit colour picker</Button>
          <ColourPickerModal
            isOpen={isOpen}
            onClose={onClose}
            onEdit={updateColourPicker}
            initialValues={{ colour, height }}
          />
        </VStack>
      </>
    </Layout>
  )
}
