import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { Home } from './pages/Home/Home'
import { Profile } from './pages/Profile/Profile'
import { ColourPicker } from './pages/ColourPicker/ColourPicker'
import { ChakraProvider, theme } from '@chakra-ui/react'

const container = document.getElementById('root')
if (!container) throw new Error('Failed to find the root element')
const root = ReactDOM.createRoot(container)
const router = createBrowserRouter([
  { path: '/', element: <Home /> },
  {
    path: 'profile',
    element: <Profile />,
  },
  {
    path: 'colour-picker',
    element: <ColourPicker />,
  },
])

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  </React.StrictMode>,
)
