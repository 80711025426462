import { Box } from '@chakra-ui/react'
import NavBar from './NavBar'

const Header = () => {
  return (
    <Box bg="white" boxShadow="md" w="100%" p={2}>
      <NavBar />
    </Box>
  )
}

export default Header
