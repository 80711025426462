import { Box, Text, Button, VStack, Heading } from '@chakra-ui/react'
import { MdFileDownload } from 'react-icons/md'
import resume from './resume.pdf'
import Layout from '../../common/Layout'
import { HobbyCard } from '../../common/HobbyCard'
import { HOBBIES } from './hobbies'
import { ChakraMotionBox } from '../../common/ChakraMotionBox'
import { motion } from 'framer-motion'

export const staggerVariants = {
  hide: { opacity: 0 },
  show: { opacity: 1, transition: { staggerChildren: 0.2 } },
}

export const Profile = () => {
  return (
    <Layout>
      <>
        <Box textAlign="center" fontSize="xl" mb={10}>
          <Text fontSize="xl">Hello, this is the About Me page.</Text>
          <Text fontSize="xl">Here is more about who I am.</Text>
          <a
            href={resume}
            download="Bei Ying's Resume"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              rightIcon={<MdFileDownload />}
              colorScheme="teal"
              variant="solid"
            >
              My resume
            </Button>
          </a>
        </Box>
        <Heading as="h3" size="lg" textAlign="center">
          My hobbies
        </Heading>
        <VStack>
          <motion.div variants={staggerVariants} initial="hide" animate="show">
            {HOBBIES.map((hobby) => (
              <ChakraMotionBox
                key={hobby.title}
                variants={staggerVariants}
                mb={3}
              >
                <HobbyCard
                  icon={hobby.icon}
                  title={hobby.title}
                  description={hobby.description}
                />
              </ChakraMotionBox>
            ))}
          </motion.div>
        </VStack>
      </>
    </Layout>
  )
}
