import { Box, Text } from '@chakra-ui/react'
import Layout from '../../common/Layout'

export const Home = () => {
  return (
    <Layout>
      <Box textAlign="center" fontSize="xl">
        <Text fontSize="xl">
          Hello from the home page of my personal website!
        </Text>
        <Text fontSize="xl">Navigate away!</Text>
      </Box>
    </Layout>
  )
}
