import { Card, CardBody, HStack, Heading, Text } from '@chakra-ui/react'
import { JSX } from 'react'

export interface HobbyCardProps {
  icon: JSX.Element
  title: string
  description: string
}
export const HobbyCard = ({ icon, title, description }: HobbyCardProps) => {
  return (
    <Card maxWidth="1100px">
      <CardBody>
        <HStack>
          <Heading as="h5" size="sm">
            {title}
          </Heading>
          {icon}
        </HStack>
        <Text>{description}</Text>
      </CardBody>
    </Card>
  )
}
