import { HStack, Text } from '@chakra-ui/react'
import githubOctocat from '../images/githubOctocat.png'

const Footer = () => {
  return (
    <HStack justifyContent="center">
      <Text fontSize="xs">Copyright © 2023 beiyingseah </Text>
      <a href="https://github.com/beiyingseah">
        <img
          src={githubOctocat}
          alt="Github icon, a feline-cephalopod hybrid, known as Octocat."
          width="18"
          height="18"
        />
      </a>
    </HStack>
  )
}

export default Footer
